<template>
  <div v-if="page_loading">
      <loading :page_loading="page_loading" />
  </div>
  <div v-else>
    <loading :loading="loading" />

    <!-- นำทาง -->
    <v-card elevation="1" class="v-card-bottom-30 hidden-xs-only">
      <v-card-title style="height: 60px;">
        <h4> {{use_language.user}} </h4>
        <v-icon>mdi-chevron-right</v-icon>
        <span class="font-weight-regular body-1"> {{use_language.manage_users}} new </span>
        <v-spacer/>
      </v-card-title>
    </v-card>

    <!-- แสดงภาพ -->
    <v-dialog v-model="ShowPhoto" max-width="500">
      <v-card class="ma-0">
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" class="text-center">
              <img class="hidden-md-and-up" :src="ShowPhotoLink" width="300">
              <img class="hidden-sm-and-down" :src="ShowPhotoLink" width="450">
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-btn text color="#1976d2" @click="Opentap(ShowPhotoLink)">{{use_language.newtab}}</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="#1976d2" @click="ShowPhoto = false">{{use_language.close}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- user -->
    <v-dialog v-model="staffShop.dialog" scrollable max-width="50%" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <v-icon size="22" color="teal darken-3">fa-address-card</v-icon>
          <span v-if="!staffShop.isUpdate" class="ml-3">{{use_language.add_user}}</span>
          <span v-else class="ml-3">{{use_language.edit_user}}</span>
          <v-spacer/>
          <v-btn icon @click="CloseDialog()"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-y-auto px-5">

          <v-form ref="formStaffShop" lazy-validation>
            <v-row>
              <v-col cols="12" class="pt-1 pb-2">
                <h3> {{use_language.username_password}} </h3>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14">  {{use_language.picture_}} : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <a id="pick-avatar">
                  <v-avatar v-if="photo.photo_link!=null && photo.photo_link!=''" class="picsize-hover" tile>
                    <img :src="photo.photo_link" class="picsize">
                  </v-avatar>
                  <v-avatar v-else class="picsize-hover" tile>
                           {{use_language.no_picture}}

                  </v-avatar>
                </a>
                <avatar-cropper
                  @uploading="handleUploading"
                  @uploaded="handleUploaded"
                  @completed="handleCompleted"
                  @error="handlerError"
                  :cropper-options="cropperOptions"
                  :labels="textButton"
                  :upload-url="uploadurl"
                  trigger="#pick-avatar"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14">         {{use_language.name}}: </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="staffShop.name"
                  @change="value => staffShop.name = Trim_value(value)"
                  ref="staffShopname"
                   :rules="staffShop.rulesName"
                  autocomplete="off"
                  outlined
                  dense hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14">         {{use_language.tel}}: </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="staffShop.phone"
                  @change="value => staffShop.phone = Trim_value(value)"
                  ref="staffShopphone"
                   :rules="staffShop.rulesPhone"
                  autocomplete="off"
                  outlined
                  dense hide-details
                  maxlength="10"
                  @keypress="isNumberWNoDot($event)"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="staffShop.id==0">
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> {{use_language.username}} : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="staffShop.username"
                  @change="value => staffShop.username = Trim_value(value)"
                  ref="staffShopusername"
                   :rules="staffShop.rulesUsername"
                  autocomplete="off"
                  outlined
                  dense hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="staffShop.id==0">
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14">  {{use_language.password}} : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="staffShop.password"
                  @change="value => staffShop.password = Trim_value(value)"
                  ref="staffShoppassword"
                   :rules="staffShop.rulesPassword"
                  autocomplete="off"
                  outlined
                  dense hide-details
                  :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_password ? 'text' : 'password'"
                  @click:append="show_password = !show_password"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="staffShop.id==0">
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> {{use_language.confirm_password}} : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="staffShop.password2"
                  @change="value => staffShop.password2 = Trim_value(value)"
                  ref="staffShoppassword2"
                  :rules="staffShop.rulesPassword2"
                  autocomplete="off"
                  outlined
                  dense hide-details
                  :append-icon="show_password2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_password2 ? 'text' : 'password'"
                  @click:append="show_password2 = !show_password2"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> {{use_language.status}} : </span>
              </v-col>
              <v-col cols="12" md="1" class="pt-1 pb-2">
                <v-switch v-model="staffShop.isActive" :disabled="staffShop.isReadonly" color="success" class="mx-2"></v-switch>
              </v-col>
              <v-col cols="12" md="8" class="pt-1 pb-2">
                <span v-if="staffShop.isActive">{{use_language.enable}}</span>
                <span v-else>{{use_language.disable}}</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> {{use_language.main_user}} : </span>
              </v-col>
              <v-col cols="12" md="1" class="pt-1 pb-2">
                <v-checkbox v-model="staffShop.isMasterUser" :disabled="staffShop.isReadonly || thisPagePermissionType !=2 " color="info"></v-checkbox>
              </v-col>
             
            </v-row>
            <v-row  v-if="!staffShop.isMasterUser">
                <v-col  cols="12" md="3" class="py-1">
                  <h3>เลือกแผนก : </h3>
                </v-col>
                <v-col  cols="12" md="9" class="py-1">
                  <v-select
                   :disabled="thisPagePermissionType !=2" 
                    v-model="staffShop.department"
                    autocomplete="off"
                    :value="staffShop.department"
                    @change="updateChangeDepartment(staffShop.department)"
                    :items="datasetDepartment"
                    item-key="text"
                    item-text="name"
                    item-value="id"
                    label=""
                    hide-details
                    dense
                    outlined
                  ></v-select>
                </v-col>
              </v-row>


            <template  >
            <v-row v-if="!staffShop.isMasterUser">
              <v-col cols="12" class="pt-1 pb-2">
                <h3> {{use_language.user_rights}} </h3>
              </v-col>
            </v-row>
         

               <div v-if="!staffShop.isMasterUser">
              <v-row v-for="(item, index) in shopPermissionShow" :key="index">
                <v-col  cols="12" md="6" class="py-1">
             
                  <v-checkbox v-if="thisPagePermissionType !=2 " :disabled="thisPagePermissionType !=2" v-model="item.isActive" class="ml-4" color="success" :label="item.nameTh"></v-checkbox>
                  <!-- <v-checkbox v-if="item.menuCode == 200 || item.menuCode == 300 || item.menuCode == 500 || item.menuCode == 600" :disabled="true" v-model="item.isActive" class="ml-4" color="success" :label="item.nameTh">
                    <template v-slot:label>                     
                      <h4 v-if="item.parentID == null"><b>{{item.nameTh}}</b></h4>
                      <span v-else style="margin-left: 20px;" >{{item.nameTh}}</span>     
                    </template>
                  </v-checkbox> -->
                  <v-checkbox v-else v-model="item.isActive" @change="check_select_menu(item)" class="ml-4" color="success" :label="item.nameTh">
                    <template v-slot:label>                     
                      <h4 v-if="item.parentID == null"><b>{{item.nameTh}}</b></h4>
                      <span v-else style="margin-left: 20px;" >{{item.nameTh}}</span>               
                    </template>
                  </v-checkbox>
                </v-col>

                <v-col  cols="12" md="6" class="py-1">
                  <v-radio-group
                   v-if="thisPagePermissionType !=2 "
                      v-model="item.authorize"
                      row
                      :disabled=" thisPagePermissionType !=2 "
                      class="mb-2 is-cod-selector"
                  >
                      <v-radio label="ดู" :value="1">
                      </v-radio>
                      <v-radio label="แก้ไข" :value="2">
                      </v-radio>
                  </v-radio-group>

                  <v-radio-group
                      v-else-if="thisPagePermissionType == 2 "
                      v-model="item.authorize"
                      row
                      :disabled="item.isActive == false || thisPagePermissionType !=2 "
                      class="mb-2 is-cod-selector"
                  >
                      <v-radio label="ดู" :value="1">
                      </v-radio>
                      <v-radio label="แก้ไข" :value="2">
                      </v-radio>
                  </v-radio-group>


                  <!-- <v-select
                    v-if="thisPagePermissionType !=2 "
                    :value="item.authorize"
                    :disabled=" thisPagePermissionType !=2 "
                    @change="value => item.authorize = value"
                    autocomplete="off"
                    :items="[{'text': 'View', 'value': 1},{'text': 'Modify', 'value': 2}]"
                    item-key="text"
                    item-value="value"
                    label=""
                    hide-details
                    dense
                    outlined
                  ></v-select>
                 
                  <v-select
                    v-else-if="thisPagePermissionType == 2 "
                    :value="item.authorize"
                    :disabled="item.isActive == false || thisPagePermissionType !=2 "
                    @change="value => item.authorize = value"
                    autocomplete="off"
                    :items="[{'text': 'View', 'value': 1},{'text': 'Modify', 'value': 2}]"
                    item-key="text"
                    item-value="value"
                    label=""
                    hide-details
                    dense
                    outlined
                  ></v-select> -->
                  <!-- <v-select
                    v-if="item.menuCode != 200 && item.menuCode != 300 && item.menuCode != 500 && item.menuCode != 600"
                    :value="item.authorize"
                    :disabled="item.isActive == false"
                    @change="value => item.authorize = value"
                    autocomplete="off"
                    :items="[{'text': 'View', 'value': 1},{'text': 'Modify', 'value': 2}]"
                    item-key="text"
                    item-value="value"
                    label=""
                    hide-details
                    dense
                    outlined
                  ></v-select> -->
                </v-col>
              </v-row>

            </div>

            <div v-else>
            
              <v-row  v-for="(item, index) in shopPermission" :key="index">
                <v-col  cols="12" md="6" class="py-1">
                  <v-checkbox input-value="true" :disabled="staffShop.isMasterUser" class="ml-4" color="success" :label="item.nameTh"></v-checkbox>
                </v-col>
                <v-col  cols="12" md="6" class="py-1">
                  <v-radio-group
                       v-model="item.authorize"
                      row
                      :disabled="staffShop.isMasterUser"
                      class="mb-2 is-cod-selector"
                      >
                      <v-radio label="ดู" :value="1">
                      </v-radio>
                      <v-radio label="แก้ไข" :value="2">
                      </v-radio>
                  </v-radio-group>

                  <!-- <v-select
                    v-if="item.menuCode != 200 && item.menuCode != 300 && item.menuCode != 500 && item.menuCode != 600"
                    :value="2"
                    :disabled="staffShop.isMasterUser"
                    @change="value => item.authorize = value"
                    autocomplete="off"
                    :items="[{'text': 'View', 'value': 1},{'text': 'Modify', 'value': 2}]"
                    item-key="text"
                    item-value="value"
                    label=""
                    hide-details
                    dense
                    outlined
                  ></v-select> -->

                </v-col>
              </v-row>
            </div>


            </template>

          
          </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-row >
            <v-col cols="12" class="pa-0" align="right">
              <v-btn :elevation="1" color="primary" @click="UpdateStaffShop()">
                <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
                <span class="fn-14"><b> {{use_language.save}} </b></span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-- department -->
    <v-dialog v-model="staffShopDepartment.dialog" scrollable max-width="50%" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <v-icon size="22" color="teal darken-3">fa-address-card</v-icon>
           <span v-if="staffShopDepartment.type == 0" class="ml-3">เพิ่มแผนก</span>
           <span v-if="staffShopDepartment.type == 1" class="ml-3">แก้ไขแผนก </span>
       
          <v-spacer/>
          <v-btn icon @click="CloseDialogDepartment()"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-y-auto px-5">

          <v-form ref="formStaffShop" lazy-validation>
          
           

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> ชื่อแผนก : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="staffShopDepartment.name"
                  @change="value => staffShopDepartment.name = Trim_value(value)"
                  ref="staffDepartmentShopname"
                  :rules="staffShop.rulesName"
                  autocomplete="off"
                  outlined
                  dense hide-details
                ></v-text-field>
              </v-col>
            </v-row>
<!-- 
            
            <v-row  v-if="staffShopDepartment.type == 1">
            
            <v-col  cols="12" md="3" class="py-1">
              <span class="fn-14">อัพเดทสิทธิ์ทั้งหมด : </span>

               
            </v-col>
            <v-col  cols="12" md="9" class="py-1">
              <v-checkbox  v-model="staffShopDepartment.isUpdate"     color="success"  >

              </v-checkbox>
            </v-col>
          </v-row> -->

       

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <h3 class="fn-14"> สิทธิ์ผู้ใช้แผนก : </h3>
              </v-col>
             
            </v-row>
            
         
              <v-row v-for="(item, index) in shopPermissionDepartment" :key="index">
            
                <v-col  cols="12" md="6" class="py-1">
                 
                   <v-checkbox  v-model="item.isActive"  @change="check_select_menu_department(item)" class="ml-4" color="success" :label="item.nameTh">
                    <template v-slot:label>                     
                      <h4 v-if="item.parentID == null"><b>{{item.nameTh}}</b></h4>
                      <span v-else style="margin-left: 20px;" >{{item.nameTh}}</span>
                      
                    </template>

                   </v-checkbox>
                </v-col>
                <v-col  cols="12" md="6" class="py-1">
                  <v-radio-group
                      v-model="item.authorize"
                      row
                      :disabled="item.isActive == false"
                      class="mb-2 is-cod-selector"
                  >
                      <v-radio label="ดู" :value="1">
                      </v-radio>
                      <v-radio label="แก้ไข" :value="2">
                      </v-radio>
                  </v-radio-group>

                  <!-- <v-select
                     :value="item.authorize"
                    :disabled="item.isActive == false"
                    @change="value => item.authorize = value"
                    autocomplete="off"
                    :items="[{'text': 'View', 'value': 1},{'text': 'Modify', 'value': 2}]"
                    item-key="text"
                    item-value="value"
                    label=""
                    hide-details
                    dense
                    outlined
                  ></v-select> -->
                </v-col>
              </v-row>
            
<!-- 
            <div v-else>
              <v-row  v-for="(item, index) in shopPermissionDepartment" :key="index">
                <v-col  cols="12" md="6" class="py-1">
                  <v-checkbox input-value="true" :disabled="staffShop.isMasterUser" class="ml-4" color="success" :label="item.nameTh"></v-checkbox>
                </v-col>
                <v-col  cols="12" md="6" class="py-1">
                  <v-select
                    
                    :value="2"
                    :disabled="staffShop.isMasterUser"
                    @change="value => item.authorize = value"
                    autocomplete="off"
                    :items="[{'text': 'View', 'value': 1},{'text': 'Modify', 'value': 2}]"
                    item-key="text"
                    item-value="value"
                    label=""
                    hide-details
                    dense
                    outlined
                  ></v-select>
                </v-col>
              </v-row>
            </div> -->


          </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-row  class="item-center">
            <v-col  cols="12" md="3" >
              <span   v-if="staffShopDepartment.type == 1" class="fn-14">อัพเดทสิทธิ์ทั้งหมด : </span>
            </v-col>
            <v-col  cols="12" md="3" >
              <v-checkbox   v-if="staffShopDepartment.type == 1"  v-model="staffShopDepartment.isUpdate"   hide-details  color="success"  >
              </v-checkbox>
            </v-col>
            <v-col cols="12" md="6"  class="pa-0" align="right">
              <v-btn :elevation="1" color="primary" @click="btnSubmitDialogDepartment()">
                <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
                <span class="fn-14"><b> {{use_language.save}} </b></span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-dialog>
    
    <!-- changePassword -->
    <v-dialog v-model="ChangePasswordDialog" scrollable max-width="50%" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <v-icon size="22" color="teal darken-3">fa-address-card</v-icon>
         แก้ไขรหัสผ่าน
          <v-spacer/>
          <v-btn icon @click="CloseDialog1()"><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="overflow-y-auto px-5">

          <v-form ref="formStaffShop1" lazy-validation>
            <v-row>
              <v-col cols="12" class="pt-1 pb-2">
                <h3> {{use_language.username_password}} </h3>
              </v-col>
            </v-row>

        

            <v-row>
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14">         {{use_language.name}}: </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                {{ staffShop.name }}
              </v-col>
            </v-row>

            <v-row  >
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> {{use_language.username}} : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
               
                <v-text-field
                  :value="staffShop.username"
                  @change="value => staffShop.username = Trim_value(value)"
                   :rules="staffShop.rulesUsername"
                  autocomplete="off"
                  outlined
                  dense hide-details
               
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row >
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14">  {{use_language.password}} : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="staffShop.password"
                  @change="value => staffShop.password = Trim_value(value)"
                  ref="staffShoppassword3"
                   :rules="staffShop.rulesPassword3"
                  autocomplete="off"
                  outlined
                  dense hide-details
                  :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_password ? 'text' : 'password'"
                  @click:append="show_password = !show_password"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row >
              <v-col cols="12" md="3" class="pt-1 pb-2">
                <span class="fn-14"> {{use_language.confirm_password}} : </span>
              </v-col>
              <v-col cols="12" md="9" class="pt-1 pb-2">
                <v-text-field
                  :value="staffShop.password2"
                  @change="value => staffShop.password2 = Trim_value(value)"
                  ref="staffShoppassword4"
                  :rules="staffShop.rulesPassword4"
                  autocomplete="off"
                  outlined
                  dense hide-details
                  :append-icon="show_password2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show_password2 ? 'text' : 'password'"
                  @click:append="show_password2 = !show_password2"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-row>
            <v-col cols="12" class="pa-0" align="right">
              <v-btn :elevation="1" color="primary" @click="btnChangePassword()">
                <v-icon size="20" class="mr-1">mdi-content-save</v-icon>
                <span class="fn-14"><b> {{use_language.save}} </b></span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-- ShowStaffDepartment -->
    <v-dialog v-model="showStaffDepartmentDidalog" scrollable max-width="25%" persistent :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card elevation="1" class="ma-0">
        <v-card-title>
          <v-icon size="18" color="teal darken-3">fa-address-card</v-icon>
           <span class="ml-2"> สมาชิกในแผนก {{ StaffDepartmentName }}</span>
          <v-spacer/>
          <v-btn icon @click="showStaffDepartmentDidalog = false "><v-icon color="danger">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider/>
        <v-list>
       <v-card-title v-if="datasetStaffDepartment <= 0">
        <template ><span>ไม่พบสมาชิก</span></template>
       </v-card-title>
        
        <v-list-item v-for="(item,index) in datasetStaffDepartment" :key="item.name" >      
           <v-list-item-title>
            <span>
            {{index +1 }}. {{ item.name }}
           </span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
      </v-card>
    </v-dialog>

    <v-card class="v-card-bottom-30">
          <v-tabs v-model="tab">
              <v-tabs-slider color="red"></v-tabs-slider>
              <v-tab v-for="item in tab_items" :key="item"> {{ item }} </v-tab>
              <v-spacer />
          </v-tabs>

          <v-divider />

          <v-tabs-items v-model="tab" touchless>
              <v-tab-item v-for="item in tab_items" :key="item">
             <!-- #region ผู้ใช้ -->
              <div v-if="tab == 0 ">
              <v-card elevation="1">
                <v-card-title class="height-57">
                  <v-icon size="22" color="teal darken-3">fa-address-card</v-icon><span class="ml-3"> {{use_language.user}}</span>
                  <v-spacer/>
                  <v-btn v-if="thisPagePermissionType==2" :elevation="1" color="primary" class="px-2" @click="OPPUser(false,null)">
                    <v-icon size="22" class="mr-1">mdi-plus</v-icon> 
                  {{use_language.add_user}}
                  </v-btn>
                </v-card-title>
                <v-divider/>
                <v-card-text>
                  <v-row class="item-center">
                    <v-col cols="12" md="1"   class="pb-3">
                    <h3 class="fn-14">ชื่อ :</h3>
                   </v-col>
                   <v-col cols="12" md="3"     class="pt-1 pb-2">
                    <v-text-field
                      v-model="search.name"
                      outlined
                      dense
                      hide-details
                      autocomplete="off"
                    ></v-text-field>
                  </v-col>
                 
                 
                  </v-row>
                  <v-row class="item-center">
                 
                  <v-col cols="12" md="1" >
                   <h3 class="fn-14">แผนก :</h3>
                   </v-col>
                   <v-col cols="12" md="3"    >
                    <v-select
                    v-model="search.department"
                    autocomplete="off"
                    :items="search.DepartmentList"
                    item-key="id"
                    item-value="id"
                    item-text="name"
                    label=""
                    hide-details
                    dense
                    outlined
                  ></v-select>
                  </v-col>
                  <v-col  >
                    <v-btn
                  :elevation="1"
                  color="primary"
                  @click="btnSearchStaff()"
                  >
                <span class="fn-14"
                  ><b>ค้นหา </b></span
                    >
                  </v-btn>
                  </v-col>
                  </v-row>
                
                </v-card-text>
                
                <v-card-text>
                  <v-data-table class="packhai-border-table"
                    :headers="header"
                    :items="dataset"
                    :items-per-page="9999999999"
                    hide-default-footer
                  >
                    <template v-slot:item.photaLink="{ item }">
                      <a v-if="item.photoLink!=null && item.photoLink!=''" icon @click="OpenPhoto(item.photoLink)">
                        <img :src="item.photoLink" class="picsize-35 mt-1">
                      </a>
                    </template>
                    <template v-slot:item.name="{ item }">
                      {{ item.name }}
                      <v-chip v-if="item.isMasterUser" class="chip-set-13" color="teal" dark> {{use_language.master_user}} </v-chip>
                    </template>
                    <template v-slot:item.no="{ item }">
                      {{ formatMoney(dataset.indexOf(item)+1) }}
                    </template>
                    <template v-slot:item.password="{ item }">
                      ********
                    </template>
                    <template v-slot:item.edit="{ item }">

                      <a v-if="isMaster == true" @click="OPPUser(true,item)"><v-icon size="16" color="orange darken-3">fa-edit</v-icon></a>
                      <a v-else-if="(thisPagePermissionType == 2 || staff_id == item.id ) && (!item.isMasterUser)" @click="OPPUser(true,item)"><v-icon size="16" color="orange darken-3">fa-edit</v-icon></a>
                    </template>
                    <template v-slot:item.delete="{ item }">
                       
                      <a v-if="thisPagePermissionType==2 && !item.isMasterUser" @click="DeleteUser(item)"><v-icon size="16" color="red darken-3">fa-trash-alt</v-icon></a>
                    </template>
                    <template v-slot:item.changepassword="{ item }">
                      <a v-if="thisPagePermissionType==2 || staff_id == item.id" @click="OpenChangePassword( item)">เปลี่ยนรหัสผ่าน</a>
                    </template>
                  </v-data-table>

                </v-card-text>
              </v-card>
              </div>
            <!-- #endregion -->
            <!-- #region แผนก -->
            <div v-if="tab == 1 ">
              <v-card elevation="1">
                <v-card-title class="height-57">
                  <v-icon size="22" color="teal darken-3">fa fa-sitemap</v-icon><span class="ml-3"> แผนก</span>
                  <v-spacer/>
                  <v-btn v-if="thisPagePermissionType==2" :elevation="1" color="primary" class="px-2" @click="OpenDialogDepartment(0,null)">
                    <v-icon size="22" class="mr-1">mdi-plus</v-icon> 
                   เพิ่มแผนก
                  </v-btn>
                </v-card-title>
                <v-divider/>
                <v-card-text>

                  <v-data-table class="packhai-border-table"
                    :headers="headerDepartment"
                    :items="datasetDepartment"
                    :items-per-page="9999999999"
                    hide-default-footer
                  >
                   
                  <template v-slot:item.no="{ item ,index}">
                    <span>{{ index +1 }}</span>
                  </template>
                  
                  <template v-slot:item.edit="{ item }">
                      <a v-if="isMaster == true" ><v-icon size="16" color="orange darken-3"  @click="OpenDialogDepartment(1,item.id)" >fa-edit</v-icon></a>
                      <a v-else-if="(thisPagePermissionType == 2 || staff_id == item.id ) && (!item.isMasterUser)"><v-icon size="16" color="orange darken-3"  @click="OpenDialogDepartment(1,item.id)" >fa-edit</v-icon></a>
                    </template>
                  <template v-slot:item.delete="{ item }">
                    <a v-if="thisPagePermissionType==2"><v-icon size="16" color="red darken-3" @click="DeleteShopDepartment(item.id)">fa-trash-alt</v-icon></a>
                  </template>
                  <template v-slot:item.info="{ item }">
                      <a v-if="thisPagePermissionType==2" @click="btnOpenDidalogShowStffDepartment(item.id)">รายละเอียด</a>
                  </template>

                  </v-data-table>

                </v-card-text>
              </v-card>
              </div>
            <!-- #endregion -->
           </v-tab-item>
          </v-tabs-items>

      </v-card>

  </div>
</template>

<script>
import Loading from '@/website/components/Loading'
import axios from 'axios'
import Swal from 'sweetalert2'
import AvatarCropper from "vue-avatar-cropper" 
import { generalService_dotnet, shopService_dotnet } from '@/website/global'
import { formatMoney, isNumberWNoDot, isNumberWithDot, DateNowNotNull, Trim_value, Trim_rules ,get_languages,checkAuthorize} from '@/website/global_function'
import { manageUser_language } from "@/website/language/manageUser_language";
import { error } from 'pdf-lib'


export default {
  components: {
    Loading,
    AvatarCropper
  },
  data: vm => ({
    thisPagePermissionType: null,
    staff_id: parseInt(localStorage.getItem("staff_id")),
    isMaster: localStorage.getItem("is_master_user") === "true",
    page_loading: true,
    loading: false,
    use_language: null,
    set_language: null,
    ChangePasswordDialog:false,

    // แสดงภาพ
    ShowPhoto: false,
    ShowPhotoLink: '',

    tab_items: ['ผู้ใช้','แผนก'],
    tab: 0,
    // user
    staffShop: {
      dialog: false,
      isUpdate: false,
      isReadonly: false,
      rulesName: [],
      rulesPhone: [],
      rulesUsername: [],
      rulesPassword: [],
      rulesPassword2: [],
      rulesPassword3:[],
      rulesPassword4:[],
      shopId: localStorage.getItem('shop_id'),
      id: 0,
      name: null,
      phone: null,
      username: null,
      password: null,
      password2: null,
      isMasterUser: false,
      isActive: true,
      photoLink: null,
      departmentList:[],
      department:null
    },
    search:
    { 
      department:null,
      DepartmentList:[{id:null,name:"--ทั้งหมด--"}],
      name:"",
    },
    show_password: false,
    show_password2: false,
    shopPermission: [],
    shopPermissionShow: [],
    header: [
      { align: 'center', text: 'No', value: 'no', sortable: false, width: '60px'  },
      { align: 'center', text: 'รูปภาพ', value: 'photaLink', sortable: false, width: '70px' },
      { align: 'left', text: 'ชื่อ', value: 'name', sortable: false  },
      { align: 'center', text: 'เบอร์โทร', value: 'phone', sortable: false, width: '150px' },
      { align: 'center', text: 'ชื่อผู้ใช้', value: 'username', sortable: false, width: '150px'  }, 
      { align: 'center', text: 'แผนก', value: 'departmentID', sortable: false, width: '150px'  }, 
      { align: 'center', text: 'แก้ไข', value: 'edit', sortable: false, width: '80px' },
      { align: 'center', text: 'ลบ', value: 'delete', sortable: false, width: '80px' },
      { align: 'center', text: 'เปลี่ยนรหัสผ่าน', value: 'changepassword', sortable: false, width: '120px' },
      
    ],
    dataset: [],

    // AvatarCropper
    cropperOptions:{
      background:false,
      viewMode:1,
      aspectRatio: 1,
      autoCropArea: 1,
      movable: false,
      zoomable: false
    },
    textButton: {
      submit:"ตกลง"
      ,cancel:"ยกเลิก"
    },
    uploadurl: generalService_dotnet + "Picture/upload-file-with-bucket",
    message: "ready",
    photo: {
      "is_base": false,
      "photo_link": null,
      "photo_uploand": {
        "file":[],
        "fileSize":0,
        "from_Picture":{},
        "from_path":''
      },
    },
    header_token:{'Content-Type': 'application/json', 'Authorization': 'Bearer '+localStorage.getItem('authorization_token')},
    //  #region แผนก 
    headerDepartment: [
      { align: 'center', text: 'No', value: 'no', sortable: false, width: '60px'  },
      { align: 'left', text: 'ชื่อแผนก', value: 'name', sortable: false  },
      { align: 'center', text: 'แก้ไข', value: 'edit', sortable: false, width: '80px' },
      { align: 'center', text: 'ลบ', value: 'delete', sortable: false, width: '80px' },
      // { align: 'center', text: 'ดูสมาชิกในแผนก', value: 'info', sortable: false, width: '180px' },
      
    ],
    departmentList:[],
    datasetDepartment:[],
    staffShopDepartment:{
      dialog: false,
      tpye:0,
      name:null,
      rulesName: [],
      id:0,
      isUpdate:false,
    },
    shopPermissionDepartment:[],
    
    showStaffDepartmentDidalog:false,
    datasetStaffDepartment:[ ],
    StaffDepartmentName:null,
    
    // #endregion
  }),
  computed: {
    
  },
  watch: {
    shopPermission(){
      console.log("เปลี่ยนค่า");
      
    }
  },
  async created() {
     await this.LoadData()

      //เก็บภาษามาไว้ใน use_language
        this.use_language = await this.get_languages(localStorage.getItem("set_language"));
        this.set_language=localStorage.getItem("set_language").toLowerCase();

        this.page_loading = false
        this.loading = false

  },
  methods: {
    get_languages,
    checkAuthorize,
    //#region API 
    async LoadData()
    {
      this.loading = true;
     let resStaffShop =   await this.GetStaffShopSearch()
     
      this.dataset =  resStaffShop
   
    
     let resDepartment =  await this.GetDepartmentList();

     if(resDepartment.status ==  "success")
     {
      this.search.DepartmentList.push(...resDepartment.items)
      this.datasetDepartment = resDepartment.items;
     }
      
     let permissiontype = this.checkAuthorize(27)

     if(permissiontype == true){
      this.thisPagePermissionType = 2;
    }else{
      this.thisPagePermissionType = 1
    }


     

    },
    async GetStaffShop(){
      this.loading = true;
      let response = await axios.post(shopService_dotnet+'StaffShop/get-staffshop-list', {
        "shopId": localStorage.getItem('shop_id')
      }, {headers: this.header_token})
      if (response.status ==200) {
        this.dataset = response.data
      }
    },
    async GetStaffShopSearch(){
      return await axios.post(shopService_dotnet+'StaffShop/get-staffshop-list-search', {
        "shopId": localStorage.getItem('shop_id'),
        "name": this.search.name,
        "departmentID": this.search.department
      }, {headers: this.header_token})
      .then(async (res) => {
          return res.data
      })
      .catch((error) => {
          this.AlertError();
          return false;
      });
    },
    async getPremissionDescription() {
        return await axios
            .post(
              shopService_dotnet + "StaffShop/get-permission-description",
                {},
                { headers: this.header_token }
            )
            .then(async (res) => {
                return res
            })
            .catch((error) => {
              this.loading = false
                this.AlertError();
                return false;
            });
    },
    async AddShopPremission(){
    },
    async UpdateDepartment(body)
    {
      return await axios
            .post(
              shopService_dotnet + "StaffShop/update-department",
                {
                  
                  "id": body.id,
                  "shopID": localStorage.getItem('shop_id'),
                  "name": body.name,
                  "isUpdate": body.isUpdate,
                  "shopPermissionDepartment": body.ShopPermissionDepartment
                },
                { headers: this.header_token }
            )
            .then(async (res) => {
                return res.data
            })
            .catch((error) => {
                this.AlertError();
                return false;
            });
            

    },
    async deleteDepartment(id)
    {
      return await axios
            .post(
              shopService_dotnet + "StaffShop/delete-department",
                {
                  
                  "id": id,
                },
                { headers: this.header_token }
            )
            .then(async (res) => {
                return res.data
            })
            .catch((error) => {
                this.AlertError();
                return false;
            });
            

    },
    async GetDepartmentList()
    {
      return await axios
            .post(
              shopService_dotnet + "StaffShop/get-department-list",
                {
                  
                  "shopID": localStorage.getItem('shop_id'),             
                },
                { headers: this.header_token }
            )
            .then(async (res) => {
                return res.data
            })
            .catch((error) => {
                this.AlertError();
                return false;
            });
            

    }, 
    async GetDepartmentIdPermission(id)
    {
      return await axios
            .post(
              shopService_dotnet + "StaffShop/get-departmentid-permission",
                {
                  
                  "id": id,             
                },
                { headers: this.header_token }
            )
            .then(async (res) => {
              
              
              if(res.data.error_description != null)
              {
                this.errorAlert(res.data.error_description);
                return false;
              }
                return res.data
            })
            .catch((error) => {
                this.AlertError();
                return false;
            });
            

    }, 
    async GetStaffShopPermissionDepartment(id)
    {
      return await axios
            .post(
              shopService_dotnet + "StaffShop/get-staffshoppermission-department",
                {
                "staffShopId": id,          
                },
                { headers: this.header_token }
            )
            .then(async (res) => {
              
                return res.data
            })
            .catch((error) => {
                this.AlertError();
                return false;
            });
            

    }, 
    async getStaffRoleDepartment(id) {
        return await axios
            .post(
              shopService_dotnet + "StaffShop/get-staff-role-department",
                {
                  "id": id
                },
                { headers: this.header_token }
            )
            .then(async (res) => {
                return res.data
            })
            .catch((error) => {
                this.AlertError();
                return false;
            });
    },
    async getStaffDepartment(id) {
        return await axios
            .post(
              shopService_dotnet + "StaffShop/get-staff-department",
                {
                  "shopID": localStorage.getItem('shop_id'),
                  "id": id
                },
                { headers: this.header_token }
            )
            .then(async (res) => {
                return res.data
            })
            .catch((error) => {
                this.AlertError();
                return false;
            });
    },
    
   
    //#endregion

    //#region user
    async OpenChangePassword(item){
      this.staffShop.rulesPassword3 = [ v => !!v || 'กรุณาระบุ', ]
      this.staffShop.rulesPassword4 = [ v => !!v || 'กรุณาระบุ', ]
      this.staffShop.rulesUsername = [ v => !!v || 'กรุณาระบุ', ]
      
      this.staffShop.id = item.id
      this.staffShop.name = item.name 
      this.staffShop.username = item.username
      this.staffShop.password = null
      this.staffShop.password2 = null
      this.ChangePasswordDialog=true;
    },
    async btnChangePassword(){
      
      if (this.$refs.formStaffShop1.validate()) {
        if (this.staffShop.password != this.staffShop.password2){
          Swal.fire({
            type: 'warning',
            title: 'รหัสผ่านไม่ตรงกัน',
            text: 'กรุณาลองใหม่อีกครั้ง',
          })
          return
        }

        let response = await axios.post(shopService_dotnet+'StaffShop/update-staffshop-password', {
          "staffShop": this.staffShop 
        },{headers: this.header_token})
        this.loading = false
         
        if (response.status==200)
        {
          if ( response.data.status=="error"){
           
            Swal.fire({ 
              type: "error",
              text:response.data.message[this.set_language],
            })
          }else{ 
            this.dataset = response.data.staffList
            this.ChangePasswordDialog = false
            Swal.fire({
              toast: true,
              timer: 1500,
              showConfirmButton: false,
              position: 'top',
              type: 'success',
              title: 'Success'
            })
          }
        } else {
          this.loading = false
          Swal.fire({
            position: "top",
            type: "error",
            text: "ไม่สามารถทำรายการได้",
          })
        }
        
      }
    
    
    },
    async btnSearchStaff(){
      this.loading = true;
        let resGetstaff = await this.GetStaffShopSearch();
        this.dataset = resGetstaff
        this.loading = false;
    },
    async OPPUser(isUpdate,item){
        this.loading = true
        this.rulesStaffShop()
        

        // ใช้อันใหม่ ใช้่ shopPermissionDepartment เก็บ

        // var permissionIndexList = [5,6,7,8,9]
        var permissionIndexList = []
        if (this.shopPermissionShow.length==0){
          // โหลดครั้งแรก เพิ่มผู้ใช้ใหม่ แก้ไขผู้ใช้
          let tempRes = await this.getPremissionDescription();
          if (tempRes.status==200){

            let setShowPermission = tempRes.data
            .filter((data) => data.menuCode != null)
            .map((item) => ({ ...item, isActive: false, authorize: 2 }));

            this.shopPermission =  JSON.parse(JSON.stringify(setShowPermission)); 
            this.shopPermissionShow =  JSON.parse(JSON.stringify(setShowPermission)); 

            // for (var i in response.data)
            // {

            //   if(response.data[i].menuCode != null){
            //     response.data[i].isActive = false
            //     response.data[i].authorize = 1
            //     if (permissionIndexList.includes(parseInt(i))){
            //       response.data[i].isActive = true
            //     }
            //     this.shopPermission.push(response.data[i])
            //   }
            // }
          }
        } else {
          if (item==null){
            // เพิ่มผู้ใช้ใหม่
            
            // this.shopPermissionShow  =  JSON.parse(JSON.stringify(this.shopPermission))
            this.shopPermissionShow =  this.shopPermissionShow.map((item) => ({ ...item, isActive: false, authorize: 2 }));
            
            
              // this.shopPermissionShow =  {...this.shopPermission};
            //  this.shopPermissionShow = this.shopPermissionShow.map((permission, index) => {
            //       // ตรวจสอบว่า index อยู่ใน permissionIndexList หรือไม่
            //       permission.isActive = permissionIndexList.includes(index);
            //       return permission;
            //   });

           
          }
        }

        // isUpdate เพิ่มผู้ใช้ไหม
        if (!isUpdate){
          
          this.staffShop.id = 0
          this.staffShop.name = null
          this.staffShop.phone = null
          this.staffShop.username = null
          this.staffShop.password = null
          this.staffShop.password2 = null
          this.staffShop.isMasterUser = false
          this.staffShop.isActive = true
          this.staffShop.photoLink = null
          this.staffShop.isReadonly = false
          this.staffShop.departmentList = []
          this.staffShop.department = null

 
          // ภาพ
          this.photo.photo_link = null
          this.photo.is_base = false

          var isMasterUser = false
          for (var a in this.dataset){
            if (this.dataset[a].isMasterUser){
              isMasterUser = true
              break
            }
          }
          if (isMasterUser)
          { this.staffShop.isMasterUser = !isMasterUser } 
          else { this.staffShop.isMasterUser = !isMasterUser }

        } else {
            
          this.staffShop.id = item.id
          this.staffShop.name = item.name
          this.staffShop.phone = item.phone
          this.staffShop.username = item.username
          this.staffShop.password = item.password
          this.staffShop.password2 = item.password
          this.staffShop.isMasterUser = item.isMasterUser
          this.staffShop.isActive = item.isActive
          this.staffShop.photoLink = item.photoLink
          this.staffShop.isReadonly = item.isMasterUser ? true : false
          this.staffShop.departmentList = []
          this.staffShop.department = null

          // ภาพ
          this.photo.photo_link = item.photoLink
          this.photo.is_base = true

          let getPermissionStaffRes = await this.GetStaffShopPermissionDepartment(item.id);
          // console.log("staffShop.isMasterUser",this.staffShop.isMasterUser);
          

          if (getPermissionStaffRes.status == "success") {
            this.staffShop.department = getPermissionStaffRes.departmentID;
            if(getPermissionStaffRes.items == null){
               
              this.shopPermissionShow  =  JSON.parse(JSON.stringify(this.shopPermission))
              
            }else{
              console.log("dfggdfg");

              const permissionMap = new Map(
              getPermissionStaffRes.items.map((item) => [item.permissionID, item])
              );
            
            // แก้ไขผู้ใช้

             // อัปเดตค่าใน setShowPermission โดยใช้ Map
             let setShowPermission = this.shopPermission.map((item) => {
                const matchedPermission = permissionMap.get(item.id);
                
                if(this.staffShop.isMasterUser == true)
                return{
                  ...item,
                  authorize :2

                }
                if (matchedPermission) {
                  return {
                    ...item,
                    isActive: true,
                    authorize: matchedPermission.authorize,
                  };
                }
               
                return item; // ค่า default ถ้าไม่เจอใน permissionMap
              });
              

            this.shopPermissionShow  =  setShowPermission
            }

          }

        }
        this.show_password = false,
        this.show_password2 = false,
        this.staffShop.isUpdate = isUpdate
        this.loading = false
        this.staffShop.dialog = true
    },
    
    async UpdateStaffShop()
    {
      await this.rulesStaffShopCheck()
      if (this.$refs.formStaffShop.validate()) {
        if (this.staffShop.password != this.staffShop.password2){
          Swal.fire({
            type: 'warning',
            title: 'รหัสผ่านไม่ตรงกัน',
            text: 'กรุณาลองใหม่อีกครั้ง',
          })
          return
        }
        this.loading = true
        if (this.staffShop.isMasterUser){
          this.staffShop.isActive = true
          for (var i in this.shopPermissionShow){
            this.shopPermissionShow[i].isActive = true
            this.shopPermissionShow[i].authorize = 2
          }
        }
        // เช็คภาพ
        var photoLink_del = null
        if (this.photo.photo_link != this.staffShop.photoLink){
          photoLink_del = this.staffShop.photoLink
          this.staffShop.photoLink = this.photo.photo_link
        }
         
        let response = await axios.post( shopService_dotnet+'StaffShop/update-staffshop', {
          "staffShop": this.staffShop,
          "shopPermission": this.shopPermissionShow,
        },{headers: this.header_token})
        this.loading = false
        if (response.status==200)
        {
          if ( response.data.status=="error"){
            Swal.fire({
              type: "error",
              text:response.data.message[this.set_language],
            })
          }else{
            // ลบ ภาพ
            if (photoLink_del!=null){ await this.DelPhoto(photoLink_del,false) }
            this.dataset = response.data.staffList
            this.staffShop.dialog = false
            Swal.fire({
              toast: true,
              timer: 1500,
              showConfirmButton: false,
              position: 'top',
              type: 'success',
              title: 'Success'
            })
          }
        } else {
          this.loading = false
          Swal.fire({
            position: "top",
            type: "error",
            text: "ไม่สามารถทำรายการได้",
          })
        }
      }
    },
    async DeleteUser(item){
      Swal.fire({
        position: 'top',
        type: 'warning',
        text: item.name+ ' ลบ ใช่ หรือ ไม่',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        allowOutsideClick: false
      }).then(async(result) => {
        if (result.value) {

          this.loading = true
          let response = await axios.post(shopService_dotnet+'StaffShop/delete-staffshop', {
            "staffShopId": item.id,
          }, {headers: this.header_token} )
          this.loading = false
          if (response.status == 200){
            this.dataset.splice(this.dataset.indexOf(item),1)
            Swal.fire({
              toast: true,
              timer: 1500,
              showConfirmButton: false,
              position: 'top',
              type: 'success',
              title: 'Success'
            })
          }else{
            Swal.fire({
              position: "top",
              type: 'error',
              text: 'ไม่สามารถทำรายการได้',
            })
          }

        }
      })
    },
    async CloseDialog(){
      await this.DelPhoto(this.photo.photo_link,this.photo.is_base)
      this.staffShop.dialog = false
    },
    async CloseDialog1(){
     
      this.ChangePasswordDialog  = false
    },
    rulesStaffShopCheck(){ 
      if(this.staffShop.id==0){
        this.staffShop.rulesPhone = [ v => !!v || 'กรุณาระบุ', ]
        this.staffShop.rulesUsername = [ v => !!v || 'กรุณาระบุ', ]
        this.staffShop.rulesPassword = [ v => !!v || 'กรุณาระบุ', ]
        this.staffShop.rulesPassword2 = [ v => !!v || 'กรุณาระบุ', ]
      }else{
        this.staffShop.rulesName = [ v => !!v || 'กรุณาระบุ', ]
      }
     
  
    },
    rulesStaffShop(){
      this.staffShop.rulesName = []
      this.staffShop.rulesPhone = []
      this.staffShop.rulesUsername = []
      this.staffShop.rulesPassword = []
      this.staffShop.rulesPassword2 = []
    },
    async updateChangeDepartment(id)
    {

      let res =await  this.getStaffRoleDepartment(id);
      
      // สร้าง Map เพื่อเก็บข้อมูลจาก permissionData
      const permissionMap = new Map(res.items.map(item => [item.permissionID, item.authorize]));

      // วนลูปผ่าน menuData และอัปเดตค่าโดยใช้ Map
      for(var item of this.shopPermissionShow )
      {
        const matchedPermission = permissionMap.get(item.id);
        
        if(matchedPermission !== undefined)
        {
          item.authorize = matchedPermission;
          item.isActive = true;
        }else{
          item.authorize = 2;
          item.isActive = false;
        }
      
        
      }

      
      
    },

    // AvatarCropper
    async handleUploading(from_data, xhr) { 
      from_data.append("bucket", 'shop/'+localStorage.getItem('shop_id'));
      this.photo.photo_uploand['from_Picture'] = from_data
      this.message = "uploading...";
    },
    async handleUploaded(response, from_data, xhr) {
       
      await this.DelPhoto(this.photo.photo_link,this.photo.is_base)
      this.photo.photo_link = response.file_path;
      this.photo.photo_uploand["from_path"] = response.file_path;
      this.photo.is_base = false
      this.message = "user avatar updated.";
      
    },
    handleCompleted(response, from_data, xhr) {
      this.message = "upload completed.";
    },
    handlerError(message, type, xhr) {
      this.message = "Oops! Something went wrong...";
    },
    async DelPhoto(photoLink,is_base){
      if (photoLink != "" && photoLink != null && !is_base) {
        let response = await axios.post(
          generalService_dotnet + "General/delete-file",
          {
            url: photoLink,
          },
          { headers: this.header_token }
        );
      }
    },

    OpenPhoto(Photo){
      this.ShowPhotoLink = Photo
      this.ShowPhoto = true
    },
    Opentap(PhotoLink){
      window.open(PhotoLink);
    },

    check_select_menu (item) {

      if(item.parentID == null)
      {
        var activeChlid = this.shopPermissionShow.filter((data) => data.parentID == item.id )
        for(var Chlid of activeChlid){
          Chlid.isActive = item.isActive;
        }
        }else{
        var disableParent = this.shopPermissionShow.filter((data) => data.parentID == item.parentID && data.isActive)
        var countActive = disableParent.length;
          if(countActive <= 0){
          var parentNot = this.shopPermissionShow.find((data) => data.id == item.parentID)
          parentNot.isActive = false;
        }else{
          var parentActive = this.shopPermissionShow.find((data) => data.id == item.parentID)
          parentActive.isActive = true;
        }
        
        
      }
      
      // var IsHave200 = false
      // var IsHave300 = false
      // var IsHave500 = false
      // var IsHave600 = false
      // for (var item_b of this.shopPermissionShow) {
      //   if(item.menuCode > 200 && item.menuCode < 300){
      //     IsHave200 = true
      //   }
      //   if(item.menuCode > 300 && item.menuCode < 400){
      //     IsHave300 = true
      //   }
      //   if(item.menuCode > 500 && item.menuCode < 600){
      //     IsHave500 = true
      //   }
      //   if(item.menuCode > 600 && item.menuCode < 700){
      //     IsHave600 = true
      //   }
      // }

      // for (var item_c of this.shopPermissionShow) {
      //   if(IsHave200){
      //     if(item_c.menuCode == 200){
      //       item_c.isActive = true
      //     }
      //   }

      //   if(IsHave300){
      //     if(item_c.menuCode == 300){
      //       item_c.isActive = true
      //     }
      //   }
      //   if(IsHave500){
      //     if(item_c.menuCode == 500){
      //       item_c.isActive = true
      //     }
      //   }
      //   if(IsHave600){
      //     if(item_c.menuCode == 600){
      //       item_c.isActive = true
      //     }
      //   }
      // }

      // var DisIsHave200 = []
      // var DisIsHave300 = []
      // var DisIsHave500 = []
      // var DisIsHave600 = []
      // for (var item_r of this.shopPermissionShow) {
      //   if(item_r.menuCode > 200 && item_r.menuCode < 300){
      //     if(item_r.isActive){
      //       DisIsHave200.push(item_r)
      //     }
      //   }
      //   if(item_r.menuCode > 300 && item_r.menuCode < 400){
      //     if(item_r.isActive){
      //       DisIsHave300.push(item_r)
      //     }
      //   }
      //   if(item_r.menuCode > 500 && item_r.menuCode < 600){
      //     if(item_r.isActive){
      //       DisIsHave500.push(item_r)
      //     }
      //   }
      //   if(item_r.menuCode > 600 && item_r.menuCode < 700){
      //     if(item_r.isActive){
      //       DisIsHave600.push(item_r)
      //     }
      //   }
      // }

      // for (var item_s of this.shopPermissionShow) {
      //   if(DisIsHave200.length == 0){
      //     if(item_s.menuCode == 200){
      //       item_s.isActive = false
      //     }
      //   }

      //   if(DisIsHave300.length == 0){
      //     if(item_s.menuCode == 300){
      //       item_s.isActive = false
      //     }
      //   }

      //   if(DisIsHave500.length == 0){
      //     if(item_s.menuCode == 500){
      //       item_s.isActive = false
      //     }
      //   }

      //   if(DisIsHave600.length == 0){
      //     if(item_s.menuCode == 600){
      //       item_s.isActive = false
      //     }
      //   }
      // }


    },
    //#endregion

    //#region  department
    async OpenDialogDepartment(type,id){
      this.loading = true
      try {
        
      
      // type 0 = add , 1 = edit
      // authorize 1 = view , 2 = modify
      this.staffShopDepartment.type  = type
      
      let tempDepartment = await this.getPremissionDescription();
       
      this.staffShopDepartment.name = null
      // สร้าง permission เริ่มต้น
      let setShowPermission = tempDepartment.data
      .filter((data) => data.menuCode != null)
      .map((item) => ({ ...item, isActive: false, authorize: 2 }));

      if(id != null)
      {
       let isActivePermission = await  this.GetDepartmentIdPermission(id);
       this.staffShopDepartment.name =  isActivePermission.name
       this.staffShopDepartment.id = isActivePermission.id
       this.staffShopDepartment.isUpdate = false;
      //  console.log("isActivePermission",isActivePermission);
         // สร้าง Map เพื่อให้ค้นหา PermissionID ได้เร็วขึ้น
        const permissionMap = new Map(
          isActivePermission.items.map((item) => [item.permissionID, item])
        );

        // อัปเดตค่าใน setShowPermission โดยใช้ Map
        setShowPermission = setShowPermission.map((item) => {
          const matchedPermission = permissionMap.get(item.id);
          if (matchedPermission) {
            return {
              ...item,
              isActive: true,
              authorize: matchedPermission.authorize,
            };
          }
          return item; // ค่า default ถ้าไม่เจอใน permissionMap
        });
       
      }
      
    
      // console.log("this.staffShopDepartment.type",this.staffShopDepartment.type);
      
      this.shopPermissionDepartment = setShowPermission
      this.loading = false
      this.staffShopDepartment.dialog = true;
       } catch (error) {
        this.AlertError(error);
        this.loading = false
        this.staffShopDepartment.dialog = true;

      }
      
    
    },
    async CloseDialogDepartment(){
      this.staffShopDepartment.dialog = false;

    },
    async btnSubmitDialogDepartment(){
      if(this.staffShopDepartment.name == null)
      {
        this.$refs.staffDepartmentShopname.focus()
          this.errorAlert("กรุณากรอกชื่อ")
      }else{
      // authorize 1 = view , 2 = modify
      let body = [];
          let tempRoleDepartmet = this.shopPermissionDepartment.filter(data => data.isActive == true)
          .map(
            (item) =>
            {
              return{...item,
                    PermissionID : item.id,
                    Authorize : item.authorize
                    }
            }
          );
          body.name = this.staffShopDepartment.name
          body.id  = this.staffShopDepartment.id ;
          body.isUpdate = this.staffShopDepartment.isUpdate
          body.ShopPermissionDepartment = tempRoleDepartmet
          
        let res = await this.UpdateDepartment(body);
          if(res.status ==  "success"){
            let GetDepartmentListNew =  await this.GetDepartmentList();
            this.datasetDepartment = GetDepartmentListNew.items;

          }
          
          this.staffShopDepartment.name = null;
          this.staffShopDepartment.id = 0;
          this.staffShopDepartment.isUpdate = false;
          this.staffShopDepartment.dialog = false;

          this.successAlert();
 
      }
     
    },
    async UpdateStaffShopDepartment(){
      console.log("asd");
      
    },
    async DeleteShopDepartment(id){

      Swal.fire({

      type: 'question',
      title: 'ต้องการลบ ?',
      text:  ' แผนก : ' + this.staffShopDepartment.name ,
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      allowOutsideClick: false
      }).then(async (result) => {
      if (result.value) {

        let res = await this.deleteDepartment(id);
       
          if(res.status ==  "success" && res.error_description == null){
            let GetDepartmentListNew =  await this.GetDepartmentList();
            this.datasetDepartment = GetDepartmentListNew.items;
            this.successAlert();
          }else{
            Swal.fire({
              
              type: 'error',
              text: res.error_description,
            })
          }
              }
      });

     
      
      this.staffShopDepartment.name = null
      this.staffShopDepartment.id = 0
      
    },
    async btnOpenDidalogShowStffDepartment(id){

      let res = await this.getStaffDepartment(id);
      console.log("res",res);
      this.StaffDepartmentName    = res.name;
      this.datasetStaffDepartment =  res.items;
      this.showStaffDepartmentDidalog = true;
    },

    check_select_menu_department (item) {

      if(item.parentID == null)
      {
      var activeChlid = this.shopPermissionDepartment.filter((data) => data.parentID == item.id )
      // console.log("activeParen",activeParent);
      for(var Chlid of activeChlid){
        Chlid.isActive = item.isActive;
      }
      }else{
      var disableParent = this.shopPermissionDepartment.filter((data) => data.parentID == item.parentID && data.isActive)
      var countActive = disableParent.length;
      if(countActive <= 0){
        var parentNot = this.shopPermissionDepartment.find((data) => data.id == item.parentID)
        parentNot.isActive = false;
      }else{
        var parentActive = this.shopPermissionDepartment.find((data) => data.id == item.parentID)
        parentActive.isActive = true;
      }

       }       

      },

    //#endregion

    //#region Alert
    async successAlert(){
      Swal.fire({
              toast: true,
              timer: 1500,
              showConfirmButton: false,
              type: 'success',
              title: 'Success'
            })
    },
    async errorAlert(body){
      Swal.fire({
              
              type: 'error',
              text: body,
            })
    },
    //#endregion
    formatMoney,
    isNumberWNoDot,
    isNumberWithDot,
    DateNowNotNull,
    Trim_value,
    Trim_rules
  }
}
</script>
